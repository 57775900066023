<template>
	<div class="main-content">
		<ktv-breadcrumb title="Training List" page="Training List" folder="Training" />
		<ktv-table
			id="tarininglist"
			:columns="columns"
			:is-loading="isLoading"
			:line-numbers="true"
			:rows="mainData.lists"
			:total-rows="mainData.total"
			:mode="modeTable"
			:filter="false"
			:search-custom-enabled="true"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button text="Add Training" icon="add" color="green-light" @click="openFormTraining()" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openFormTraining(props.row)">
							<img :src="iconedit" class="imgicon"> Edit & Detail
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteTraining(props.row)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field == 'TrainingImage'">
					<b-img :src="props.row.TrainingImage" fluid alt="Fluid image" width="50" />
				</span>
				<span v-else-if="props.column.field == 'TrainingStatus'">
					<b-badge :variant="props.row.TrainingStatus === 'PUBLISHED' ? 'success' : 'warning'">{{ props.row.TrainingStatus }}</b-badge>
				</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { KtvBreadcrumb, KtvTable, KtvButton } from "@/components"

	export default {
		metaInfo: {
			title: "Training",
		},
		components: { KtvBreadcrumb, KtvTable, KtvButton },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				isLoading: false,
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Commodity",
						field: "TrainingCommodityText",
						thClass: "text-left",
					},
					{
						label: "Category",
						field: "CategoryLanguageText",
						thClass: "text-left",
					},
					{
						label: "Sub Category",
						field: "SubCategoryLanguageText",
						thClass: "text-left",
					},
					{
						label: "Title",
						field: "TrainingTitle",
						thClass: "text-left",
					},
					{
						label: "Summary",
						field: "TrainingSummary",
						thClass: "text-left",
					},
					// {
					// 	label: "Main Image",
					// 	field: "TrainingImage",
					// 	thClass: "text-left",
					// },
					{
						label: "Status",
						field: "TrainingStatus",
						thClass: "text-left",
					},
					// {
					// 	label: "Tags",
					// 	field: "TrainingTags",
					// 	thClass: "text-left",
					// },
				],
				rows: [],
				mainData: {
					lists: [],
					total: 0,
				},
				modeTable: "remote",
				serverParams: {
					rowPerpage: 10,
					currentPage: 1,
					columnFilters: { SubCategoryLanguageText: "" },
					sort: [
						{
							field: "TrainingId",
							type: "ASC",
						},
					],
				},
			}
		},
		computed: {},
		mounted() {
			this.getGridMainData()
		},
		methods: {
			getGridMainData() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.training.list)
					.then((r) => {
						this.isLoading = false
						if (r.success) {
							this.mainData.lists = r.results.data
							this.mainData.total = r.results.total
						} else {
							this.$swal("Error!", r.error_massage, "error")
						}
					})
					.catch((e) => {
						this.isLoading = false
						this.$swal("Error!", e.response.data.error_message, "error")
					})
			},
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			openFormTraining(val) {
				let id = val?.TrainingId || ""
				this.$router.push({ path: "/training/create", query: { id: id } }).catch(() => true)
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.getGridMainData()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.getGridMainData()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.getGridMainData()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.getGridMainData()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { CategoryLanguageText: event },
				})
				this.getGridMainData()
			},
			deleteTraining(item) {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((result) => {
					if (result.value) {
						this.showLoadingMessage("Deleting Data...")
						let payload = {
							TrainingId: item.TrainingId,
						}
						this.$http
							.Delete(payload, this.$urlApi.training.header.delete)
							.then((response) => {
								if (response.success) {
									this.getGridMainData()
									this.$swal.closeModal()
									this.$swal("Deleted!", "Your data has been deleted.", "success")
								} else {
									this.$swal.closeModal()
									this.$swal("Error!", "process error", "error")
								}
							})
							.catch((error) => {
								this.$swal("Failed!", error.response.data.error_message, "error")
								// this.$swal("Error!", "process error", "error");
							})
					}
				})
			},
		},
	}
</script>

<style scoped>
.btn-view-edit {
	padding: 0;
	border: none;
	text-decoration: inherit
}
.dropdownitem-action:hover a, .dropdownitem-action:focus a{
	background-color: #CAEAFF !important;
}
</style>
